<template>
  <div class="successOrder">
    <div class="successOrder-title">订单生成</div>
    <div class="mainbody">
      <div class="success-reg">
        <div class="success-text">
          <a-icon type="check-circle" class="icon" />
          <div class="text">
            <p>订单生成成功，请进行合同签署</p>
            <!-- <p>委托代表人：请 张三 使用手机号18668121234 接收短信</p> -->
            <p><img src="" alt="" /></p>
          </div>
        </div>
        <div class="success-btn">
          <router-link to="/"
            ><div class="go-index">
              <p>进入首页</p>
            </div></router-link
          >
          <div class="go-shop">
            <router-link v-if="$route.query.toPath" :to="`${$route.query.toPath}?entityId=${$route.query.purchaserId}`"> <p>查看订单</p></router-link>
            <router-link v-else :to="`/order?id=${$route.query.id}&entityId=${$route.query.purchaserId}`"> <p>查看订单</p></router-link>
          </div>
        </div>
        <div class="hint">
          <a-icon
            type="exclamation-circle"
          />温馨提示：91再生网不会以订单异常、系统升级为由要求您点击任何网址链接进行退款操作。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
   data(){
     return{
     }
   },

}
</script>
<style lang="less" scoped>
.successOrder {
  margin-bottom: 100px;
  .successOrder-title {
    max-width: 1440px;
    min-width: 990px;
    @media screen and (max-width: 1440px){
      padding: 0 15px;
      padding-top: 30px;
    }
    margin: auto;
    padding: 30px 0 0 0;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    color: #999999;
  }
  .mainbody {
    margin: 20px auto;
    width: 1200px;
    @media screen and (max-width: 1440px){
      width: 990px;
    }
    height: 572px;
    background-color: #ffffff;
    position: relative;
    .success-reg {
      padding: 100px 0 0 0;
      text-align: center;
      .success-text {
        .text {
          :nth-child(1) {
            font-size: 24px;
            padding: 30px 0 0 0;
            color: #666666;
          }
          :nth-child(2) {
            padding: 20px 0 0 0;
            color: #999999;
            font-size: 16px;
          }
        }
      }
    }
    .success-btn {
      display: flex;
      justify-content: center;
      .go-index,
      .go-shop {
        margin-left: 20px;
        width: 200px;
        font-size: 14px;
        height: 45px;
        line-height: 45px;
      }
      .go-index {
        color: #ffffff;
        background-color: rgba(26, 188, 156, 1);
        :hover {
          background-color: #0fce96;
          opacity: 0.8;
        }
      }
      .go-shop {
        color: #0fce96;
        outline: 1px solid #0fce96;
        :hover {
          background-color: #0fce96;
          color: #ffffff;
        }
      }
    }
    .hint {
      padding: 30px 0 0 0;
      color: #999999;
      font-size: 14px;
    }
  }
}
.icon {
  color: #0fce96;
  font-size: 100px;
}
</style>